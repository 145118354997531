"use client";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { GrCloudComputer } from "react-icons/gr";
import Slider from "react-slick";

const TechnologySection = ({
  title = "What Variety of Web Solutions Does",
  colouredTitle = "Our Web Designing Company Offer?",
  description = "What Variety of Web Solutions Does",
  imageSrc = "",
  imageAlt = "",
  items = [],
  bgColor = "",
}) => {
  const fallbackImages = [
    "/images/services/advanced-keyword-analysis.jpg",
    "/images/services/advanced-software-services.jpg",
    "/images/services/affordable-business-logos.jpg",
    "/images/services/affordable-high-quality-ux-design.jpg",
    "/images/services/affordable-seo-maintenance.jpg",
    "/images/services/affordable-web-design.jpg",
    "/images/services/affordable-web-development-mumbai.jpg",
    "/images/services/affordable-website-design.jpg",
    "/images/services/affordable-website-hosting.jpg",
    "/images/services/affordable-web-solutions.jpg",
    "/images/services/affordable-wordPress-design-solutions.jpg",
    "/images/services/ai-powered-web-design.jpg",
    "/images/services/andheri-east-web-design.jpg",
    "/images/services/andheri-west-web-design-company.jpg",
    "/images/services/android-app-development.jpg",
    "/images/services/app-design-experts-services.jpg",
    "/images/services/app-integration.jpg",
    "/images/services/app-interface-design-services.jpg",
    "/images/services/app-wireframe-prototype-design.jpg",
    "/images/services/article-writing-services.jpg",
    "/images/services/ashopify-redesign-services.jpg",
    "/images/services/atlanta-web-design-and-development.jpg",
    "/images/services/attorney-website-design.jpg",
    "/images/services/austin-tx-web-design-company.jpg",
    "/images/services/automated-email-packages.jpg",
    "/images/services/background-removal.jpg",
    "/images/services/bankruptcy-attorney-website-design.jpg",
    "/images/services/bespoke-ecommerce-solutions.jpg",
    "/images/services/bespoke-uxui-design.jpg",
    "/images/services/bespoke-web-design.jpg",
    "/images/services/best-web-hosting.jpg",
    "/images/services/biotech-branding-and-marketing.jpg",
    "/images/services/blazing-fast-website.jpg",
    "/images/services/blog-design-solutions.jpg",
    "/images/services/blog-writing-services.jpg",
    "/images/services/bootstrap-application-development.jpg",
    "/images/services/bootstrap-ecommerce-development.jpg",
    "/images/services/bootstrap-frontend-development.jpg",
    "/images/services/bootstrap-webdesign-services.jpg",
    "/images/services/branding_graphic_desig_services.jpg",
    "/images/services/branding-marketing.jpg",
    "/images/services/branding-solutions.jpg",
    "/images/services/bulk-email-solutions.jpg",
    "/images/services/busines-analytics-services.jpg",
    "/images/services/business-centric-mobile-remedies.jpg",
    "/images/services/business-email-strategies.jpg",
    "/images/services/business-marketing-advertising.jpg",
    "/images/services/business-website.jpg",
    "/images/services/canberra-web-design-and-development.jpg",
    "/images/services/cheap-web-hosting.jpg",
    "/images/services/chorley-web-design-services.jpg",
    "/images/services/cloud-based-mobile-services.jpg",
    "/images/services/cloud-content-migration.jpg",
    "/images/services/cloud-hosting.jpg",
    "/images/services/cms-implementation.jpg",
    "/images/services/cms.jpg",
    "/images/services/colorado-web-design-services.jpg",
    "/images/services/color-correction.jpg",
    "/images/services/community-website-design.jpg",
    "/images/services/competitive-analysis-services.jpg",
    "/images/services/competitor-keyword-research.jpg",
    "/images/services/component-based-architecture.jpg",
    "/images/services/comprehensive-digital-marketing.jpg",
    "/images/services/comprehensive-it-solutions.jpg",
    "/images/services/comprehensive-shopify-maintenance.jpg",
    "/images/services/content-creation-services.jpg",
    "/images/services/content-management.jpg",
    "/images/services/content-management-system.jpg",
    "/images/services/content-marketing-strategies.jpg",
    "/images/services/content-optimization-services.jpg",
    "/images/services/content-optimization-solutions.jpg",
    "/images/services/content-promotion-strategies.jpg",
    "/images/services/content-strategy-development.jpg",
    "/images/services/content-strategy.jpg",
    "/images/services/continuous-shopify-website-support.jpg",
    "/images/services/conversion-optimization.jpg",
    "/images/services/conversion-rate-optimization.jpg",
    "/images/services/convert-psd-to-wordpress.jpg",
    "/images/services/copywriting-services.jpg",
    "/images/services/cork-nextjs-react-website-services.jpg",
    "/images/services/cpanel-hosting.jpg",
    "/images/services/creative-innovative-designs.jpg",
    "/images/services/creative-portfolio-design.jpg",
    "/images/services/creative-solutions.jpg",
    "/images/services/crm-mobile-solutions.jpg",
    "/images/services/cross-browser-compatibility.jpg",
    "/images/services/cross-platform-app-development.jpg",
    "/images/services/cross-platform-app-interface-design.jpg",
    "/images/services/custom-biotech-website-development.jpg",
    "/images/services/custom-blog-design.jpg",
    "/images/services/custom-blog-layout-creation.jpg",
    "/images/services/custom-bootstrap-development.jpg",
    "/images/services/custom-branding.jpg",
    "/images/services/custom-business-website-design.jpg",
    "/images/services/custom-cms-packages.jpg",
    "/images/services/custom-design.jpg",
    "/images/services/custom-development.jpg",
    "/images/services/custom-digital-marketing.jpg",
    "/images/services/custom-ecommerce-solutions.jpg",
    "/images/services/custom-family-lawyers-website-design.jpg",
    "/images/services/custom-gatsby-development.jpg",
    "/images/services/custom_illustration_services.jpg",
    "/images/services/customized-ecommerce-website-design.jpg",
    "/images/services/customized-shopify-web-design.jpg",
    "/images/services/custom-landing-page-designs.jpg",
    "/images/services/custom-laravel-application-development.jpg",
    "/images/services/custom-laravel-solutions.jpg",
    "/images/services/custom-laravel-web-application.jpg",
    "/images/services/custom-logo-creation.jpg",
    "/images/services/custom-nextjs-design.jpg",
    "/images/services/custom-online-design.jpg",
    "/images/services/custom-portfolio-design.jpg",
    "/images/services/custom-psd-to-wordpress.jpg",
    "/images/services/custom-reactjs-development.jpg",
    "/images/services/custom-real-estate-web-design.jpg",
    "/images/services/custom-real-estate-web-development.jpg",
    "/images/services/custom-responsive-design.jpg",
    "/images/services/custom-roofing-website-design.jpg",
    "/images/services/custom-shopify-design.jpg",
    "/images/services/custom-shopware-development.jpg",
    "/images/services/custom-shopware-theme-design.jpg",
    "/images/services/custom-smartphone-app-design.jpg",
    "/images/services/custom-social-media-graphics.jpg",
    "/images/services/custom-tailwind-development.jpg",
    "/images/services/custom-vuejs-programming.jpg",
    "/images/services/custom-web-application.jpg",
    "/images/services/custom-web-application-services.jpg",
    "/images/services/custom-web-design.jpg",
    "/images/services/custom-web-development.jpg",
    "/images/services/custom-web-development-mumbai.jpg",
    "/images/services/custom-website-design.jpg",
    "/images/services/Custom-Website-Design.jpg",
    "/images/services/custom-website-development.jpg",
    "/images/services/custom-websites.jpg",
    "/images/services/custom-wordpress-blog-design.jpg",
    "/images/services/custom-wordpress-design.jpg",
    "/images/services/custom-wordpress-development.jpg",
    "/images/services/custom-wordpress-theme-development.jpg",
    "/images/services/custom-wordpress-website-design.jpg",
    "/images/services/cyber-woman-in-a-public-place-bionic-prosthetics.jpg",
    "/images/services/dallas-web-design-services.jpg",
    "/images/services/data-analysis-reporting.jpg",
    "/images/services/data-encryption.jpg",
    "/images/services/data-management-services.jpg",
    "/images/services/dedicated-device-security.jpg",
    "/images/services/delaware-nextjs-react-website-services.jpg",
    "/images/services/denver-web-design-and-development.jpg",
    "/images/services/design-2.jpg",
    "/images/services/design-3.jpg",
    "/images/services/design-5.jpg",
    "/images/services/design-development.jpg",
    "/images/services/designing-10.jpg",
    "/images/services/developing-mobile-application.jpg",
    "/images/services/digital-asset-organization.jpg",
    "/images/services/digital-design.jpg",
    "/images/services/digital-marketing-2.jpg",
    "/images/services/digital-marketing.jpg",
    "/images/services/digital-marketing-services.jpg",
    "/images/services/digital-marketin-reporting.jpg",
    "/images/services/digital-news-platform-design.jpg",
    "/images/services/digital-partners.jpg",
    "/images/services/direct-advertising-tools.jpg",
    "/images/services/document-management-integration.jpg",
    "/images/services/domain-hosting-solutions.jpg",
    "/images/services/domain-registration-assistance.jpg",
    "/images/services/dos-protection.jpg",
    "/images/services/ecommece-website-maintenance.jpg",
    "/images/services/ecommerce-biotech-webdesign.jpg",
    "/images/services/ecommerce-design.jpg",
    "/images/services/ecommerce-development.jpg",
    "/images/services/ecommerce-development-services.jpg",
    "/images/services/ecommerce-digital-marketing.jpg",
    "/images/services/ecommerce-gaming-app-design.jpg",
    "/images/services/ecommerce-hosting-expertise.jpg",
    "/images/services/ecommerce-integration.jpg",
    "/images/services/ecommerce.jpg",
    "/images/services/ecommerce-management-services.jpg",
    "/images/services/ecommerce-mobile-services.jpg",
    "/images/services/ecommerce-platform-development.jpg",
    "/images/services/ecommerce-platform-services.jpg",
    "/images/services/ecommerce-service-provider.jpg",
    "/images/services/ecommerce-solutions.jpg",
    "/images/services/ecommerce-store-design.jpg",
    "/images/services/ecommerce-store.jpg",
    "/images/services/ecommerce-ui-ux-services.jpg",
    "/images/services/ecommerce-web-design-services.jpg",
    "/images/services/ecommerce-web-development.jpg",
    "/images/services/ecommerce-web-development-mumbai.jpg",
    "/images/services/ecommerce-website-customization.jpg",
    "/images/services/ecommerce-website-design-and-development.jpg",
    "/images/services/ecommerce-website-design.jpg",
    "/images/services/ecommerce-website-development.jpg",
    "/images/services/ecommerce-website-management.jpg",
    "/images/services/email-campaign-management.jpg",
    "/images/services/email_campaigns_services.jpg",
    "/images/services/email-hosting-services.jpg",
    "/images/services/email-marketing.jpg",
    "/images/services/enterprise-mobile-services.jpg",
    "/images/services/enterprise-mobile-strategy.jpg",
    "/images/services/enterprise-web-development.jpg",
    "/images/services/exclusive-hosting-deals.jpg",
    "/images/services/expert-app-design-consultancy.jpg",
    "/images/services/expert-mobile-designs.jpg",
    "/images/services/expert-shopify-troubleshooting.jpg",
    "/images/services/expert-tailwind-developers.jpg",
    "/images/services/fast-busines-website-launch.jpg",
    "/images/services/fast-loading-times.jpg",
    "/images/services/fast-online-business-services.jpg",
    "/images/services/fast-ssd-web-hosting.jpg",
    "/images/services/firewall-protection.jpg",
    "/images/services/flexible-shopify-maintenance-packages.jpg",
    "/images/services/freelance-developer-consultation.jpg",
    "/images/services/freelance-developer-recruitment.jpg",
    "/images/services/freelance-graphic-designer-in-mumbai.jpg",
    "/images/services/freelance-website-developer-in-mumbai.jpg",
    "/images/services/freelance-website-developer-support.jpg",
    "/images/services/freelance-website-maintenance.jpg",
    "/images/services/freelance-website-seo-services.jpg",
    "/images/services/front-end-development.jpg",
    "/images/services/full-servic-web-agency.jpg",
    "/images/services/full-stack-app-development.jpg",
    "/images/services/gatsby-development-consultation.jpg",
    "/images/services/gatsbyjs-development.jpg",
    "/images/services/gatsbyjs-maintenance-support.jpg",
    "/images/services/gatsbyjs-migration-services.jpg",
    "/images/services/gatsbyjs-performance-optimization.jpg",
    "/images/services/gatsby-js-plugin-development.jpg",
    "/images/services/google-business-management.jpg",
    "/images/services/goregaon-web-design-services.jpg",
    "/images/services/graphic-design-1.jpg",
    "/images/services/graphic-design-services.jpg",
    "/images/services/green-web-hosting.jpg",
    "/images/services/group-of-webdevelopers.jpg",
    "/images/services/group-of-website-designers.jpg",
    "/images/services/healthcare-branding.jpg",
    "/images/services/healthcare-marketing-solutions.jpg",
    "/images/services/healthcare-seo-services.jpg",
    "/images/services/healthcare-website-design.jpg",
    "/images/services/high-quality-psd-wordpress-conversion.jpg",
    "/images/services/high-quality-startup-logos.jpg",
    "/images/services/high-quality-wordpress-designs.jpg",
    "/images/services/homepage.jpg",
    "/images/services/home-services-website-design.jpg",
    "/images/services/hospital-website-design.jpg",
    "/images/services/image-resizing.jpg",
    "/images/services/improved-customer-service.jpg",
    "/images/services/influencer-marketing.jpg",
    "/images/services/innovative-app-development.jpg",
    "/images/services/integrated-mobile-framework.jpg",
    "/images/services/interactive-landingpage-design.jpg",
    "/images/services/interactive-mobileapp-layout-design.jpg",
    "/images/services/ios-ap-development.jpg",
    "/images/services/irvine-web-design-services.jpg",
    "/images/services/javascript-expertise.jpg",
    "/images/services/javaScript-integration.jpg",
    "/images/services/kevalahealth.jpg",
    "/images/services/keyword-research-consultation.jpg",
    "/images/services/keyword-research-services.jpg",
    "/images/services/landingpage-design-revision.jpg",
    "/images/services/landingpage-design-services-consultation.jpg",
    "/images/services/landing-pages-popups-forms.jpg",
    "/images/services/laravel-application-development.jpg",
    "/images/services/laravel-customization-service.jpg",
    "/images/services/laravel-custom-web-services.jpg",
    "/images/services/laravel-data-migration.jpg",
    "/images/services/laravel-developer-training.jpg",
    "/images/services/laravel-development.jpg",
    "/images/services/laravel-development-services.jpg",
    "/images/services/laravel-extension-development.jpg",
    "/images/services/laravel-integration-solutions.jpg",
    "/images/services/laravel-maintenance-support.jpg",
    "/images/services/laravel-web-development.jpg",
    "/images/services/laravel-web-service.jpg",
    "/images/services/las-vegas-web-design.jpg",
    "/images/services/lawfirm-website-design.jpg",
    "/images/services/lead-generation.jpg",
    "/images/services/legal-website-design.jpg",
    "/images/services/link-building-techniques.jpg",
    "/images/services/linux-windows-hosting.jpg",
    "/images/services/local-search-engine-optimization.jpg",
    "/images/services/local-seo-analysis.jpg",
    "/images/services/local-seo-consultation.jpg",
    "/images/services/local-seo-keyword-research.jpg",
    "/images/services/loc_at_custom_website_design.jpg",
    "/images/services/london-ecommerce-website-development.jpg",
    "/images/services/london-web-design-and-development.jpg",
    "/images/services/long-tail-keyword-research.jpg",
    "/images/services/los-angeles-web-design-and-development.jpg",
    "/images/services/machine-learning-consulting.jpg",
    "/images/services/maintenance-support.jpg",
    "/images/services/malad-web-designers-and-developers.jpg",
    "/images/services/managed-hosting-plans.jpg",
    "/images/services/managed-seo-services.jpg",
    "/images/services/managed-website-services.jpg",
    "/images/services/marketing-support.jpg",
    "/images/services/medical-website-design.jpg",
    "/images/services/member-only-website-services.jpg",
    "/images/services/membership-management-software.jpg",
    "/images/services/membership-site-platforms.jpg",
    "/images/services/membership-website-design.jpg",
    "/images/services/membership-website-services.jpg",
    "/images/services/mobile-app-design-agency-services.jpg",
    "/images/services/mobile-app-development.jpg",
    "/images/services/mobile-app-dev.jpg",
    "/images/services/mobileapp-screen-design.jpg",
    "/images/services/mobileapp-visual-design-solutions.jpg",
    "/images/services/mobile-compatibility.jpg",
    "/images/services/mobile-development.jpg",
    "/images/services/mobile-ecommerce-design.jpg",
    "/images/services/mobile-friendly-biotech-websites.jpg",
    "/images/services/mobile-friendly-design.jpg",
    "/images/services/mobile-friendly-roofing-services.jpg",
    "/images/services/mobile-optimization.jpg",
    "/images/services/mobile-responsive-design.jpg",
    "/images/services/mobile-tech-advancements.jpg",
    "/images/services/mobile-uiux-design-services.jpg",
    "/images/services/mobile-user-interface-design-services.jpg",
    "/images/services/modern-portfolio-design.jpg",
    "/images/services/monthly-seo-analysis.jpg",
    "/images/services/mumbai-develope-consultation.jpg",
    "/images/services/mumbai-developer-training.jpg",
    "/images/services/mumbai-web-design.jpg",
    "/images/services/mumbai-web-development.jpg",
    "/images/services/native-hybrid-app-design.jpg",
    "/images/services/network-solutions-provider.jpg",
    "/images/services/new-jersey-web-design-and-development.jpg",
    "/images/services/news-portal-design.jpg",
    "/images/services/news-portal-development.jpg",
    "/images/services/news-website-design.jpg",
    "/images/services/news-website-development.jpg",
    "/images/services/nextjs-ecommerce-design.jpg",
    "/images/services/nextjs-mobile-design.jpg",
    "/images/services/nextjs-web-design.jpg",
    "/images/services/nextjs-website-design.jpg",
    "/images/services/nextjs-website-redesign.jpg",
    "/images/services/north-carolina-digital-marketing.jpg",
    "/images/services/off-page-link-bulding.jpg",
    "/images/services/off-page-optimization.jpg",
    "/images/services/ojai-web-design.jpg",
    "/images/services/ongoing-maintenance-website-support.jpg",
    "/images/services/ongoing-seo.jpg",
    "/images/services/ongoing-website-maintenance.jpg",
    "/images/services/online-community-designs.jpg",
    "/images/services/online-directory-submissions.jpg",
    "/images/services/online-membership-site.jpg",
    "/images/services/online-news-portal-services.jpg",
    "/images/services/online-store-creation.jpg",
    "/images/services/online-store-design.jpg",
    "/images/services/online-store-management.jpg",
    "/images/services/on-page-seo.jpg",
    "/images/services/onsite-seo-audit.jpg",
    "/images/services/orlando-web-design.jpg",
    "/images/services/outsourcing-services.jpg",
    "/images/services/payment-integration.jpg",
    "/images/services/pay-monthly-payments.jpg",
    "/images/services/performance-optimization.jpg",
    "/images/services/personalized-blog-design.jpg",
    "/images/services/personalized-web-design.jpg",
    "/images/services/photo-enhancement.jpg",
    "/images/services/photographer.jpg",
    "/images/services/photo-manipulation.jpg",
    "/images/services/plugin-customization.jpg",
    "/images/services/premium-corporate-designs.jpg",
    "/images/services/premium-wordpress-services.jpg",
    "/images/services/premium-wordpress-web-development.jpg",
    "/images/services/print_design_services.jpg",
    "/images/services/product-page-optimization.jpg",
    "/images/services/product-photography-editing.jpg",
    "/images/services/product-showcase.jpg",
    "/images/services/professional-blog-design.jpg",
    "/images/services/professional-custom-built-sites.jpg",
    "/images/services/professional-developers.jpg",
    "/images/services/professional-digital-marketing.jpg",
    "/images/services/professional-ecommerceb-design.jpg",
    "/images/services/professional-game-development.jpg",
    "/images/services/professional-graphics-layout.jpg",
    "/images/services/professional-ios-app-design.jpg",
    "/images/services/professional-logo-design.jpg",
    "/images/services/professional-marketing-software.jpg",
    "/images/services/professional-nextjs-design.jpg",
    "/images/services/professional-portfolio-design.jpg",
    "/images/services/professional-roofing-website-development.jpg",
    "/images/services/professional-seo-audit.jpg",
    "/images/services/professional-seo-auditing.jpg",
    "/images/services/professional-tailwind-services.jpg",
    "/images/services/professional-web-design.jpg",
    "/images/services/professional-web-developer-mumbai.jpg",
    "/images/services/professional-web-development.jpg",
    "/images/services/professional-web-maintenance.jpg",
    "/images/services/professional-website-design.jpg",
    "/images/services/professional-website-maintenance.jpg",
    "/images/services/professional-wordpress-design.jpg",
    "/images/services/professional-wordpress-design-services.jpg",
    "/images/services/professional-wordpress-services.jpg",
    "/images/services/professional-writin-services.jpg",
    "/images/services/programmers-and-designers.jpg",
    "/images/services/programming-10.jpg",
    "/images/services/programming-11.jpg",
    "/images/services/programming-12.jpg",
    "/images/services/programming-14.jpg",
    "/images/services/programming-15.jpg",
    "/images/services/programming-18.jpg",
    "/images/services/programming-1.jpg",
    "/images/services/programming-23.jpg",
    "/images/services/programming-2.jpg",
    "/images/services/programming-3.jpg",
    "/images/services/programming-4.jpg",
    "/images/services/programming-6.jpg",
    "/images/services/programming-9.jpg",
    "/images/services/project-handling.jpg",
    "/images/services/property-listings.jpg",
    "/images/services/proramming-22.jpg",
    "/images/services/psd-slicing-wordpress.jpg",
    "/images/services/psd-to-theme-creation.jpg",
    "/images/services/psd-to-wordpress-conversion.jpg",
    "/images/services/psd-to-wordpress-developers.jpg",
    "/images/services/qa-testing-team.jpg",
    "/images/services/quick-website-design.jpg",
    "/images/services/react-js-next.js-and-gatsby-js-1.jpg",
    "/images/services/react-js-next.js-and-gatsby-js.jpg",
    "/images/services/react-js-next.js-and-gatsby-js.jpg",
    "/images/services/react-native-app-development.jpg",
    "/images/services/react-native-consulting.jpg",
    "/images/services/react-native-developers.jpg",
    "/images/services/react-native-maintenance.jpg",
    "/images/services/react-native-support.jpg",
    "/images/services/real-estate-cms.jpg",
    "/images/services/real-estate-ecommerce-development.jpg",
    "/images/services/real-estate-mobile-app-development.jpg",
    "/images/services/real-estate-seo-optimization.jpg",
    "/images/services/real-estate-ui-ux-design.jpg",
    "/images/services/real-estate-website-design.jpg",
    "/images/services/real-estate-website-maintenance.jpg",
    "/images/services/regular-shopify-updates.jpg",
    "/images/services/reliable-secure-hosting.jpg",
    "/images/services/reporting-analysis.jpg",
    "/images/services/reputation-management-services.jpg",
    "/images/services/reseller-agency.jpg",
    "/images/services/reseller-web-hosting.jpg",
    "/images/services/responsive-android-app-design.jpg",
    "/images/services/responsive-biotech-design.jpg",
    "/images/services/responsive-design.jpg",
    "/images/services/responsive-digital-marketing.jpg",
    "/images/services/responsive-ecommerce-design.jpg",
    "/images/services/responsive-ecommerce-storefront.jpg",
    "/images/services/responsive-hopware-design.jpg",
    "/images/services/responsive-portfolio-design.jpg",
    "/images/services/responsive-roofing-design.jpg",
    "/images/services/responsive-shopify-design.jpg",
    "/images/services/responsive-sites.jpg",
    "/images/services/responsive-software-development.jpg",
    "/images/services/responsive-tailwind-webdesign.jpg",
    "/images/services/responsive-uiux-design.jpg",
    "/images/services/responsive-web-design.jpg",
    "/images/services/responsive-website-design.jpg",
    "/images/services/responsive-wordpress-design.jpg",
    "/images/services/retail-mobile-services.jpg",
    "/images/services/retouching-images.jpg",
    "/images/services/rochdale-web-design.jpg",
    "/images/services/seamless-communication-services.jpg",
    "/images/services/seo-analytics-services.jpg",
    "/images/services/seo-audit-services.jpg",
    "/images/services/seo-backlink-building.jpg",
    "/images/services/seo-biotech-services.jpg",
    "/images/services/seo-consulting-services.jpg",
    "/images/services/seo-content-writing.jpg",
    "/images/services/seo_enhancement_services.jpg",
    "/images/services/seo-friendly-websites.jpg",
    "/images/services/seo-integration.jpg",
    "/images/services/seo-keyword-strategy.jpg",
    "/images/services/seo-maintenance-services.jpg",
    "/images/services/seo-marketing-services.jpg",
    "/images/services/seo-optimization.jpg",
    "/images/services/seo-optimized-landingpages.jpg",
    "/images/services/seo-services.jpg",
    "/images/services/seo-site-audit.jpg",
    "/images/services/seo-strategy-development.jpg",
    "/images/services/seo-strategy.jpg",
    "/images/services/shared-dedicated-hosting.jpg",
    "/images/services/shared-hosting.jpg",
    "/images/services/shopify-custom-development.jpg",
    "/images/services/shopify-customization.jpg",
    "/images/services/shopify-developer-services.jpg",
    "/images/services/shopify-expert-services.jpg",
    "/images/services/shopify-maintenance-services.jpg",
    "/images/services/shopify-store-maintenance.jpg",
    "/images/services/shopify-theme-customization-services.jpg",
    "/images/services/shoppingcart.jpg",
    "/images/services/shopware-agency.jpg",
    "/images/services/shopware-customization.jpg",
    "/images/services/shopware-experts.jpg",
    "/images/services/shopware-plugin-integration.jpg",
    "/images/services/shopware-services.jpg",
    "/images/services/shopware-solutions.jpg",
    "/images/services/shopware-store-redesign-services.jpg",
    "/images/services/shopware-template-customization.jpg",
    "/images/services/shopware-your-next-big-ecommerce-platform.jpg",
    "/images/services/simplified-payment-systems.jpg",
    "/images/services/single-page-applications.jpg",
    "/images/services/small-business-solutions.jpg",
    "/images/services/social_media_advertising.jpg",
    "/images/services/social-media-brand-management.jpg",
    "/images/services/social-media-content.jpg",
    "/images/services/social-media-designs.jpg",
    "/images/services/social_media_graphics.jpg",
    "/images/services/social-media-integration.jpg",
    "/images/services/social-media-management.jpg",
    "/images/services/social-media-marketing.jpg",
    "/images/services/social-network-design.jpg",
    "/images/services/socia-media-content.jpg",
    "/images/services/sooke-web-design-agency.jpg",
    "/images/services/specialist-html5-css3-designs.jpg",
    "/images/services/ssl-certificate.jpg",
    "/images/services/store-customization.jpg",
    "/images/services/strategic-consulting.jpg",
    "/images/services/strategic-marketing-solutions.jpg",
    "/images/services/successful-relationships-longterm.jpg",
    "/images/services/tablet-app-design-services.jpg",
    "/images/services/tailored-mobil-solutions.jpg",
    "/images/services/tailor-made-wordpress-design-services.jpg",
    "/images/services/tailor-web-design.jpg",
    "/images/services/tailwind-css-design.jpg",
    "/images/services/tailwind-css-development.jpg",
    "/images/services/target.jpg",
    "/images/services/team-content-writers.jpg",
    "/images/services/team-digital-marketers.jpg",
    "/images/services/team-digital-marketing-experts.jpg",
    "/images/services/team-ecommerce-experts.jpg",
    "/images/services/team-mobile-developeers.jpg",
    "/images/services/team-nextjs-developers.jpg",
    "/images/services/team-react-developers.jpg",
    "/images/services/team-seo-experts.jpg",
    "/images/services/team-shopify-developers.jpg",
    "/images/services/team-shopify-experts.jpg",
    "/images/services/team-web-developers.jpg",
    "/images/services/team-website-designers.jpg",
    "/images/services/team-wordpress-designers.jpg",
    "/images/services/team-wordpress-developers.jpg",
    "/images/services/technical-seo-audit.jpg",
    "/images/services/technical-seo.jpg",
    "/images/services/technical-seo-management.jpg",
    "/images/services/technical-seo-services.jpg",
    "/images/services/texas-website-design-services.jpg",
    "/images/services/theme-customization.jpg",
    "/images/services/theme-development.jpg",
    "/images/services/third-party-integrations.jpg",
    "/images/services/top-professional-hosting.jpg",
    "/images/services/toronto-non-profit-website-design.jpg",
    "/images/services/ui-ux-design.jpg",
    "/images/services/Understanding-the-Audience.jpg",
    "/images/services/unique-branding-solutions.jpg",
    "/images/services/unique-portfolio-design.jpg",
    "/images/services/unique-web-design.jpg",
    "/images/services/unlimited-web-hosting-packages.jpg",
    "/images/services/user-experience-design.jpg",
    "/images/services/user-experience.jpg",
    "/images/services/user-friendly-interfaces.jpg",
    "/images/services/user-friendly-roofing-company-website.jpg",
    "/images/services/ux-ui-design.jpg",
    "/images/services/ux-ui-digital-marketing.jpg",
    "/images/services/ux-ui-elements.jpg",
    "/images/services/versova-web-design-services.jpg",
    "/images/services/video_marketing_services.jpg",
    "/images/services/visual-content-creation.jpg",
    "/images/services/vps-cloud-hosting.jpg",
    "/images/services/vuejs-development-services.jpg",
    "/images/services/web-application-development-mumbai.jpg",
    "/images/services/web-applications.jpg",
    "/images/services/web-based-mobile-solutions.jpg",
    "/images/services/web-content-optimization.jpg",
    "/images/services/web-content-services.jpg",
    "/images/services/webdesigner.jpg",
    "/images/services/webdesign-partners.jpg",
    "/images/services/webdesign-team.jpg",
    "/images/services/web-developers.jpg",
    "/images/services/web-development.jpg",
    "/images/services/web-development-services.jpg",
    "/images/services/web_graphic_design.jpg",
    "/images/services/web-maintenance.jpg",
    "/images/services/web_promotions_strategies.jpg",
    "/images/services/websit-builder.jpg",
    "/images/services/website-backup.jpg",
    "/images/services/website-content-writing.jpg",
    "/images/services/website-designers.jpg",
    "/images/services/website-designing-services.jpg",
    "/images/services/website-design.jpg",
    "/images/services/website-hosting-services.jpg",
    "/images/services/website-maintenance.jpg",
    "/images/services/website-management-solutions.jpg",
    "/images/services/website-monitoring.jpg",
    "/images/services/website-online-courses-education.jpg",
    "/images/services/website-performance-optimization.jpg",
    "/images/services/website-redesign-digital-marketing.jpg",
    "/images/services/website-redesign.jpg",
    "/images/services/website-redesign-services.jpg",
    "/images/services/website-repair-services.jpg",
    "/images/services/website-security-audit.jpg",
    "/images/services/website-seo-audit.jpg",
    "/images/services/website-theme-services.jpg",
    "/images/services/website-update-design.jpg",
    "/images/services/Website-web-Company.jpg",
    "/images/services/web-traffic-reporting.jpg",
    "/images/services/wetumpka-web-design.jpg",
    "/images/services/What-is-Bespoke-Website-Design.jpg",
    "/images/services/white-label-branding.jpg",
    "/images/services/why-choose-us.jpg",
    "/images/services/winter-cms-design.jpg",
    "/images/services/winter-cms-development.jpg",
    "/images/services/winter-cms-support.jpg",
    "/images/services/winter-cms-theme.jpg",
    "/images/services/wordpress-coding-from-psd.jpg",
    "/images/services/wordpress-design-services.jpg",
    "/images/services/wordpress-development-services.jpg",
    "/images/services/wordPress-hosting-services.jpg",
    "/images/services/wordpress-plugin-customization.jpg",
    "/images/services/wordpress-theme-coding.jpg",
    "/images/services/wordpress-theme-customization.jpg",
    "/images/services/wordpress-theme-design-development.jpg",
    "/images/services/wordpress-web-development.jpg",
    "/images/services/wordpress-website-creation.jpg",
    "/images/services/wordpress-website-design.jpg",
  ];

  const [selectedImage, setSelectedImage] = useState(imageSrc);

  const handleError = () => {
    const randomFallbackImage =
      fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
    setSelectedImage(randomFallbackImage);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    //centerMode: true,
    //centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="bg-white py-10 md:py-16 lg:py-20 w-full">
        <div className="max-w-screen-xl mx-7 lg:mx-auto md:flex md:flow-row items-center justify-center">
          <div className="flex-1 md:w-1/3 pr-2 md:pr-10">
            <h3 className="text-black text-2xl">Technology Advantages</h3>
            <h2 className="font-extrabold text-black">
              {title}
              <div className="text-colorOrange-700">{colouredTitle}</div>
            </h2>
            <p
              className="text-black"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            {imageSrc && (
              <Image
                src={selectedImage}
                alt={imageAlt}
                onError={handleError}
                width={200}
                height={300}
                className=""
              />
            )}
          </div>
          <div className="flex-1 md:w-2/3">
            <Slider {...settings}>
              {items.map((item, index) => {
                const [beforeColon, afterColon] = item.name.split(":");
                const bgColor =
                  index % 2 === 0 ? "bg-colorOrange-500" : "bg-neutral-800";
                const textColor =
                  index % 2 === 0 ? "text-neutral-800" : "text-colorOrange-400";
                return (
                  <div key={index} className="p-0 md:p-4 pt-8">
                    <div
                      className={`${bgColor} p-6 rounded-lg shadow-lg min-h-[30rem]`}
                    >
                      <GrCloudComputer
                        className={`self-start w-16 h-16 mr-3 mb-4 ${textColor}`}
                      />
                      <div className={`text-lg font-medium ${textColor}`}>
                        <div className={`text-xl font-bold ${textColor}`}>
                          {item.link ? (
                            <Link
                              href={item.link}
                              className={`text-xl uppercase font-bold mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                            >
                              {beforeColon}
                            </Link>
                          ) : (
                            <div
                              href={item.link}
                              className={`text-2xl uppercase font-bold mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                            >
                              {beforeColon}
                            </div>
                          )}
                        </div>
                        <div className={`text-lg pt-4 ${textColor}`}>
                          {afterColon}
                        </div>
                      </div>
                      {item.link && (
                        <Link
                          href={item.link}
                          className={`text-sm uppercase font-medium mt-4 inline-block ${textColor} hover:text-colorRed-500`}
                        >
                          <FaArrowAltCircleRight
                            size={24}
                            className={`${textColor}`}
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologySection;
